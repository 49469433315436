<template>
  <div>
    <!--    导航栏-->
    <tabs @parentEvent="btn" ref="tabs"></tabs>
    <!--   end 导航栏-->

    <!--    中间部分-->
    <div class="main">
      <div class="solution-banner-container">
        <div class="page-content">
          <!--          <div class="solution-banner-content">-->
          <!--            <div class="solution-banner-title">消费电子</div>-->
          <!--            <div class="solution-banner-desc">-->
          <!--              信息技术的生命周期正逐年缩短。在“新技术集中应用、消费格局转变和智能设备普及”等因素综合影响下，消费电子产品从电子化、联网化正向“智能化及智慧化”演变，速度明显加快，新应用也在不断涌现。-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <a href="#" class="button">方案咨询</a>-->
        </div>
      </div>
      <div class="card-dark">
        <!--          <h1>行业合作案例</h1>-->
        <div class="insight-list page-content">
          <div
            class="insight-item"
            v-for="(item, i) in getList.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )"
            :key="i"
            @mouseleave="showMouseleave()"
            @mouseenter="showmouseenter(i)"
          >
            <img :src="item.Cover" alt="" />
            <div class="insight-item-txt">
              <div class="insight-item-title">{{ item.Title }}</div>
              <div class="insight-introduce">
                <span>{{ item.Brief }}</span>
              </div>
            </div>
            <!--经过出现的样式-->
            <div class="insight-item-click" v-show="isShow === i">
              <el-tooltip
                :content="item.Title"
                placement="top"
                v-if="item.Title.length >= 9"
              >
                <div class="title">{{ item.Title }}</div>
              </el-tooltip>
              <div class="title" v-else>{{ item.Title }}</div>
              <img src="../assets/xcx.png" alt="" />
              <div class="txt">请扫描上方小程序码查看详情</div>
              <div class="btnss" v-if="i === 0" @click="chene">
                <!-- <span>查看详情</span> -->
                <!-- <img src="../assets/right_yuan.png" alt="" /> -->
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pagesize"
          background
          layout="prev, pager, next"
          :total="getList.length"
        >
        </el-pagination>
      </div>
    </div>
    <!--    <div class="card">-->
    <!--      <h1>行业解决方案</h1>-->
    <!--      <div class="page-content insight-card-list">-->
    <!--        <div class="no-logistics">-->
    <!--          <a href="#">-->
    <!--            <div class="solution-item ">-->
    <!--              <img src="../assets/index/1.png" alt="">-->
    <!--              <div class="solution-item-content">-->
    <!--                <h1>智慧屏及智能电视解决方案</h1>-->
    <!--                <div class="desc">-->
    <!--                  家庭娱乐和教育生活类应用需求的增加，促使“智能电视”作为新的产品界面链接家庭生活。近两年，传统ICT、互联网和电视厂相继进入新型智慧家居市场，推动新业态生长，通过支持“运动健康、互娱和教育”类应用，推动智能家电互联互通。-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="points">-->
    <!--                <div class="point-item" v-for="item in 3">算法效果业界领先</div>-->
    <!--              </div>-->
    <!--              <div class="btns-contianer">-->
    <!--                <a href="#" class="button">了解详情</a>-->
    <!--                <a href="#" class="button">联系我们</a>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--        end 中间部分-->
    <!--    底部-->
    <footers id="botton"></footers>
    <!--    end 底部-->
  </div>
</template>

<script>
import tabs from "@/components/tabs";
import footers from "@/components/footers";

export default {
  props: [],
  components: {
    tabs,
    footers
  },
  data() {
    return {
      height: 9999999,
      getList: [],
      currentPage: 1, //初始页
      pagesize: 8, //    每页的数据
      userList: [],
      isShow: -1
    };
  },

  mounted() {
    let _this = this;
    // 接口
    this.$axios
      .get(
        "https://api.bluexii.cn/xiimol/v1/public/corpcmscontentlist/74/0/10",
        {
          // 还可以直接把参数拼接在url后边
        }
      )
      .then(function(res) {
        _this.getList = res.data.Data;
      })
      .catch(function(error) {
        console.log(error);
      });
    // this.height =  window.getComputedStyle(this.$refs.element).scrollHeight;
    // let middle = this.$refs["element"];
    // this.height = middle.scrollHeight;
  },
  methods: {
    btn() {
      document.getElementById("botton").scrollIntoView();
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function(size) {
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
    },
    // 点击显示小程序二维码
    showmouseenter(index) {
      this.isShow = index;
    },
    //  鼠标离开隐藏二维码
    showMouseleave() {
      this.isShow = -1;
    },
    chene() {
      this.$router.push("/cooperation/garden");
    }
  }
};
</script>

<style lang="less" scoped>
.page-content {
  max-width: 1160px;
  margin: 0 auto;
  text-align: center;
}

.button {
  display: inline-block;
  width: 180px;
  height: 46px;
  line-height: 46px;
  border-radius: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.1s linear 0s;
}

a {
  text-decoration: none;
}

.main {
  .solution-banner-container {
    min-height: 450px;
    background-color: #000;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url("../assets/yuanquanli.png");

    .solution-banner-content {
      margin: auto;
      padding-top: 110px;
      color: #fff;
      width: 780px;

      .solution-banner-title {
        font-size: 32px;
        line-height: 60px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .solution-banner-desc {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 30px;
      }
    }

    a {
      background-color: #fff;
      color: #0e57a2;
      border: 1px solid #fff;
    }
  }

  .card-dark {
    //background: #f2f3f5;
    background-color: #fff;
    padding: 80px 0;

    h1 {
      font-size: 23px;
      font-weight: 600;
      color: #333;
      line-height: 60px;
      text-align: center;
      position: relative;
    }

    h1:after {
      content: "";
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 42px;
      height: 6px;
      background-color: #007aff;
    }

    .insight-list {
      min-height: 740px;
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(3, 240px);
      //grid-template-columns: repeat(3, 33.33%);
      grid-column-gap: 24px;
      justify-content: center;
      text-align: left;

      .insight-item {
        width: 240px;
        margin-top: 50px;
        position: relative;
        box-shadow: 0px 2px 8px 0px rgba(200, 200, 200, 0.5);

        height: 320px;

        img {
          width: 240px;
          //height: 200px;
          margin: 0 auto;
          display: inline-block;
        }

        .insight-item-txt {
          .insight-item-title {
            margin: 9px 0;

            font-size: 20px;
            font-weight: 600;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            padding: 0 13px;
          }

          .insight-introduce {
            padding: 0 13px;
            line-height: 24px;
            font-size: 14px;
            color: #666;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 12px;
            font-weight: 400;
            text-align: justify;

            span {
              width: 200px;
              text-align: justify;
            }
          }
        }

        .insight-item-click {
          position: absolute;
          left: 0;
          top: 0;
          width: 240px;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          text-align: center;

          .title {
            margin-top: 72px;
            font-size: 19px;
            font-weight: 600;
            color: #ffffff;
            line-height: 37px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 13px;
          }

          img {
            width: 153px;
            height: 143px;
            margin: 24px 0 8px 0;
          }

          .txt {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
          }

          .btnss {
            position: absolute;
            right: 30px;
            bottom: 20px;
            font-size: 18px;
            color: #fff;
            cursor: pointer;

            span {
              vertical-align: middle;
              margin-right: 10px;
            }

            img {
              width: 24px;
              height: 24px;
              vertical-align: middle;
              margin: 0;
            }
          }
        }
      }
    }

    .el-pagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
  }
}
</style>
